/**
 * Main Script File
 *
 * This is loaded in the footer of all pages. This is the preferred place to
 * load all script files.
 */

require('./thirdParty/jquery.alert/jquery.alert');
require('./thirdParty/jquery.circularProgressIndicator/jquery.circularProgressIndicator');
require('./thirdParty/jquery.cschotspots/jquery.cschotspots');
require('./thirdParty/jquery.cscutils/jquery.cscutils');
require('./thirdParty/jquery.parallax/jquery.parallax');
require('./thirdParty/jquery.sideDrawer/jquery.sideDrawer');
require('./thirdParty/jquery.stepBar/jquery.stepBar');
require('./thirdParty/jquery.wizard/jquery.wizard');

var processInclude = require('./components/processInclude');

// Should be included before all other files to ensure its available for consumption
processInclude(require('./debug'));
processInclude(require('./components/authStateValidator'));

global.quickview = require('./product/quickView');

const set = require('lodash/set');
set(window, '_.set', set);

// Hooks must be executed before any other code runs
processInclude(require('./components/historyHooks'));

$(document).ready(function () {
    processInclude(require('./components/menu'));
    processInclude(require('./components/accountDrawers'));
    processInclude(require('./components/progressiveProfiling'));
    processInclude(require('./components/passwordVisibility'));
    processInclude(require('./components/cookie'));
    processInclude(require('./components/headerOffset'));
    /**
      * Not currently using OOTB consent tracking. Commented out to improve performance.
      * processInclude(require('./components/consentTracking'));
      */
    processInclude(require('./components/footer'));
    processInclude(require('./components/miniCart'));
    processInclude(require('./components/collapsibleItem'));
    processInclude(require('./components/search'));
    processInclude(require('./components/clientSideValidation'));
    processInclude(require('./components/clientRequest'));
    processInclude(require('./components/countdown'));
    processInclude(require('./components/countrySelectorOverlay'));
    processInclude(require('./components/languageSelectorOverlay'));
    processInclude(require('./components/accessibility'));
    processInclude(require('./components/modals'));
    processInclude(require('./components/video'));
    processInclude(require('./thirdParty/emailSpellCheckerInit'));
    processInclude(require('./thirdParty/swiperInit'));
    processInclude(require('./thirdParty/vanillaLazyLoad'));
    processInclude(require('./components/carousel'));
    processInclude(require('./brand/main'));
    processInclude(global.quickview);
    processInclude(require('./product/pdpActions'));
    processInclude(require('./product/productTile'));
    processInclude(require('./components/loyalty'));
    processInclude(require('./components/membership'));
    processInclude(require('./components/reservationTimer'));
    processInclude(require('./dataLayer/dataLayerPersistence'));

    /* Example of including an AEM script
    processInclude(require('./aem/example')); */
    processInclude(require('./aem/modalComponent'));
    processInclude(require('./aem/globalMarketingBanner'));
    processInclude(require('./aem/overflowSlider'));
    processInclude(require('./aem/tabbedContainer'));
    processInclude(require('./aem/image'));

    const event = new Event('main-js-includes');
    // event to signal main.js includes have processed
    window.dispatchEvent(event);
});

require('./thirdParty/bootstrap');
require('./components/spinner');

