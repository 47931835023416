/* global registerButtonAsRecaptchaTrigger */
'use strict';

const rewardsTracker = require('./rewardsTracker');
const keyboardAccessibility = require('./keyboardAccessibility');
const utils = require('./utils');
const initProfilingCpi = require('./progressiveProfiling').initProfilingCpi;

const openAccountDrawerKey = 'oad';
const signInKey = 'signin';
let openAccountDrawer = false;

let mainDrawer;
let reAuthDrawer;

class AccountDrawer {
    constructor(drawerEvent, reauthenticate, redirectUrl) {
        const thisDrawer = this;

        this.drawerId = crypto.randomUUID();
        this.isOpen = true;

        this.$drawer = $.drawer({
            src: $('.siteheader').data('lazy-load-account-drawer-url') + (reauthenticate ? '?reauthenticate=true' : ''),
            size: 'sm',
            class: 'account-drawer',
            destroyOnClose: false,
            returnFocusTo: drawerEvent ? drawerEvent.currentTarget : 'body'
        }).on('hide.drawer', function (e, closeEvent) {
            thisDrawer.isOpen = false;

            // also close mobile nav
            const $clickedButton = closeEvent && $(closeEvent.currentTarget);

            if (!($clickedButton && $clickedButton.hasClass('js-action__menuback'))) {
                // if we click the mobile "back" button, just close the account drawer.
                // otherwise, also close the mobile nav drawer.
                $('.sitenav').closeMenuItem();
                $('html').removeClass('menu-active');
                $.enableScroll();

                // SOREL: also toggle the close button back to hamburger menu
                $('.js-action__menutoggle').removeClass('close');
            }
        }).on('shown.drawer', function () {
            thisDrawer.isOpen = true;

            window.dispatchEvent(new Event('accountPopover:shown:dtm'));
            window.dispatchEvent(new Event('accountDrawer:shown:dtm'));
        }).on('contentAsyncLoaded.drawer', function () {
            const $authWizardContainer = thisDrawer.$drawer.find('.js-auth-wizard');

            if ($authWizardContainer.length) {
                const AuthWizard = require('./AuthWizard');
                this.authWizard = new AuthWizard($authWizardContainer, redirectUrl, reauthenticate, thisDrawer.$drawer);

                // Set up listener to remove 'oad' queryparam if registration form is submitted
                if (openAccountDrawer) {
                    $('body').one(`login:register:success.${this.drawerId}`, function (e, data) {
                        if (data.redirectUrl) {
                            // eslint-disable-next-line no-param-reassign
                            data.redirectUrl = utils.removeQueryParam(openAccountDrawerKey, data.redirectUrl).toString();
                        }
                        utils.removeQueryParamFromCurrentUrl(openAccountDrawerKey);
                    });
                }
            } else {
                // Progressive Profiling Setup
                initProfilingCpi(thisDrawer.$drawer.find('.progress'));

                // Load My Account Drawer Content
                rewardsTracker.loadRewardsTracker(thisDrawer.$drawer.find('.account-content'));

                // After user is logged in, prevent additional openings of account drawer
                if (openAccountDrawer) {
                    utils.removeQueryParamFromCurrentUrl(openAccountDrawerKey);
                }
            }
        });

        $(document).on(`keydown.${this.drawerId}`, function (e) {
            if (e.key === 'Escape') {
                thisDrawer.close();
            }
        });
    }

    open(redirectUrl) {
        if (this.authWizard) {
            this.authWizard.setFormRedirectData(redirectUrl);
        }

        if (this.isOpen) {
            return;
        }

        this.isOpen = true;
        this.$drawer.open();
    }

    remove() {
        $(document).off(`.${this.drawerId}`);
        this.$drawer.next('.drawer__backdrop').remove();
        this.$drawer.remove();
    }

    close() {
        if (!this.isOpen) {
            return;
        }

        this.$drawer.close();
        this.isOpen = false;
    }
}

/**
 * Open Account Drawer
 * @param {event} drawerEvent The click event that called this funtion
 * @param {boolean} reAuthenticate query param to flag for reauthentication
 */
function openDrawer(drawerEvent, reAuthenticate) {
    const redirectUrl = (drawerEvent && $(drawerEvent.currentTarget).data('redirect')) || '';

    const drawerManager = {
        get targetDrawer() {
            return reAuthenticate ? reAuthDrawer : mainDrawer;
        },
        set targetDrawer(value) {
            if (reAuthenticate) {
                reAuthDrawer = value;
            } else {
                mainDrawer = value;
            }
        }
    };

    if (drawerManager.targetDrawer) { // the drawer has already been created, just show it.
        drawerManager.targetDrawer.open(redirectUrl);
    } else { // load the account drawer
        drawerManager.targetDrawer = new AccountDrawer(drawerEvent, reAuthenticate, redirectUrl);
    }
}

/**
 * Keyboard events
 */
function keyboardEvents() {
    keyboardAccessibility('.navbar-header .siteheader__account',
        {
            // Escape key (27) is handled below - ESC on any element will close the drawer if it's open.
            38: function () { // up
                mainDrawer.close();
                return true;
            },
            40: function () { // down
                openDrawer();
                return true;
            }
        },
        function () {
            return this;
        }
    );
}

module.exports = {
    init: function () {
        keyboardEvents();

        // Open the Account Drawer if the request contains a queryparam to do so
        const searchParams = new URLSearchParams(window.location.search);
        let openForSignIn = searchParams.has(signInKey);
        openAccountDrawer = searchParams.has(openAccountDrawerKey);

        if (openAccountDrawer || (openForSignIn && !window.clientData.user.isLoggedIn)) {
            openDrawer();
        }

        // Remove signin from queryparam if present
        if (openForSignIn) {
            utils.removeQueryParamFromCurrentUrl(signInKey);
        }

        $(document)
            .on('click', '.js-drawer-login, .js-drawer-registration, .js-action__account', function (e) {
                e.preventDefault();
                openDrawer(e);
            })
            .on('accountDrawer:show', function () {
                openDrawer();
            })
            .on('accountDrawer:hide', function () {
                if (mainDrawer) {
                    mainDrawer.close();
                }

                if (reAuthDrawer) {
                    reAuthDrawer.close();
                }
            })
            .on('accountDrawer:reauthenticate', function (e, data) {
                const $existingOpenDrawer = $('.drawer.show').data('drawer');

                if ($existingOpenDrawer) {
                    $existingOpenDrawer.close();
                }

                openDrawer(e, true);

                reAuthDrawer.$drawer.one('login:success', 'form.login', function () {
                    let isNavigatingAway = false;

                    $(window).on('beforeunload', () => {
                        isNavigatingAway = true;
                    });

                    data.onAuthenticated.call(this);

                    /**
                     * Close the reauth drawer in case the customer remains on the same page.
                     * Need to wait for login:success to finish so it properly incorporates data set via onAuthenticated.
                     * It also gives the page a chance to redirect if applicable before affecting the UI.
                     */
                    setTimeout(() => {
                        if (!isNavigatingAway) {
                            reAuthDrawer.close();
                        }
                    });
                });

                reAuthDrawer.$drawer.on('hide.drawer', function () {
                    if ($existingOpenDrawer) {
                        // Let the hide operation finish then reopen
                        setTimeout(() => $existingOpenDrawer.open());
                    }

                    // Remove drawer so on next access, its in a default/original state
                    reAuthDrawer.remove();
                    reAuthDrawer = undefined;
                });
            });
    }
};
