'use strict';

/**
 * Returns the value of an auth permission.
 * @param {string} permission - the permission to check
 * @returns {boolean} value of the permission
 */
function customerHasPermission(permission) {
    const value = window.clientData &&
        window.clientData.user &&
        window.clientData.user.permissionsForCurrentAuthState &&
        window.clientData.user.permissionsForCurrentAuthState[permission];

    return !!value; // force to boolean
}

/**
 * Checks permissions and adds click event listeners to a specified target to handle reauthentication logic.
 *
 * When the target is clicked, the function prevents the default action and triggers
 * an authentication event. If authentication is successful, it either redirects to
 * the specified URL or triggers a click on the target element.
 *
 * @param {string} selector - A selector for the target elements to attach the click event listener.
 * @param {string} permission - The permission key to check in the current authentication state.
 */
function registerClickAuthValidator(selector, permission) {
    if (customerHasPermission(permission)) {
        return;
    }

    $(document).on('click.reauth', selector + ':not(body)', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();

        const $target = $(e.currentTarget);

        $(document).trigger('accountDrawer:reauthenticate', {
            onAuthenticated: function () {
                try {
                    $(document).off('.reauth');

                    /**
                     * Re-trigger the original button click.
                     * Calling JQuery trigger('click') will not work, must use the native click function.
                     * Reference: https://stackoverflow.com/a/18654635
                     */
                    $target[0].click();

                    // 'this' references the login form in the callback. We add a data attribute to allow early exit from redirect behaviors upon successful login.
                    $(this).data('skip-default-nav', true);
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.error('Original click was not propely routed after re-authentication:', error.message);
                }
            }
        });
    });
}

/**
 * Registers page load event handlers for a specified target to handle reauthentication logic.
 *
 * @param {string} selector - A selector for the target elements to attach the click event listener.
 * @param {string} permission - The permission key to check in the current authentication state.
 *
 */
function registerPageAuthValidator(selector, permission) {
    if (!$(selector).length || customerHasPermission(permission)) {
        return;
    }

    $(window).on('load', function () {
        $(document).trigger('accountDrawer:reauthenticate', {
            onAuthenticated: function () {
                $(document).off('.reauth');
            }
        });
    });
}

/**
 * Registers event handlers for elements requiring PII and Checkout authentication.
 */
function initAuthValidators() {
    /**
     * If they're not allowed to at least view their basic customer info
     * then their state is not applicable to re-authentication.
     */
    if (!customerHasPermission('canViewBasicPersonalInfo')) {
        return;
    }

    registerClickAuthValidator('.js-pii_auth-required', 'canViewAndEditPii');
    registerClickAuthValidator('.js-checkout_auth-required', 'canPlaceOrder');
    registerPageAuthValidator('body.js-checkout_auth-required', 'canPlaceOrder');
}

module.exports = {
    initAuthValidators: initAuthValidators
};
